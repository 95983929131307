import tocbot from 'tocbot'

// toc
export function toc() {
  tocbot.init({
    tocSelector: '.table-of-contents',
    contentSelector: '.l-contents__inner',
    // headingSelector: 'h1, h2, h3',
    headingSelector: 'h2, h3',
    ignoreSelector: '.toc-ignore', // スキップするclass
    hasInnerContainers: true,
    scrollSmooth: false,
    orderedList: false, // ul
  });
}

import "@babel/polyfill";
import { window_size } from "./modules/theme";
import { viewport_less_than_360 } from "./modules/viewport";
import { humburger_menu, drawer_menu } from "./modules/humburger-menu";
import { global_menu, global_menu_current } from "./modules/global-menu";
// import { scroll_hint } from "./modules/scrollhint";
import { kvSplide } from "./modules/splide";
import { year_select } from "./modules/news";
import { toc } from "./modules/toc";
// import { rwd_image_maps } from "./modules/rwd-image-maps";
import { image_map_resizer } from "./modules/image-map-resizer";

window_size();
viewport_less_than_360();
global_menu();
// global_menu_current();
humburger_menu();
drawer_menu();
// scroll_hint();
kvSplide();
// year_select();
toc();
// rwd_image_maps();
image_map_resizer();

/**
 * humburger
 */
export function humburger_menu() {
  const hamButton = document.getElementById('hamburger-button');
  const humHeader = document.getElementById('site-header');
  const humBody   = document.getElementById('body');
  const hamDrawer = document.getElementById('drawer');
  const closeInDrawer = document.getElementById('close-drawer');
  hamButton.addEventListener('click', function() {
    hamButton.classList.toggle('is-open');
    humHeader.classList.toggle('is-open');
    humBody.classList.toggle('is-locked');

    if ( hamDrawer.getAttribute('aria-hidden') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

  }, false);

  closeInDrawer.addEventListener('click', function() {
    hamButton.classList.toggle('is-open');
    humHeader.classList.toggle('is-open');
    humBody.classList.toggle('is-locked');

    if ( hamDrawer.getAttribute('aria-hidden') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

  }, false);

  /**
   * ドロワーメニュー内のアンカーリンク#を押した時に閉じる
   * drawer > .drawer-menu > li > a:href #
   */
  const humDrawerLink = document.querySelector('.drawer-menu a[href^="/#"]');
  if (!humDrawerLink){
    return false;
  }

  humDrawerLink.addEventListener('click', function() {
    hamButton.classList.remove('is-open');
    humBody.classList.remove('is-locked');

    if ( hamDrawer.getAttribute('aria-expanded') == 'false' ) {
      hamDrawer.setAttribute('aria-expanded', true);
    } else {
      hamDrawer.setAttribute('aria-expanded', false);
    }
    if ( hamDrawer.getAttribute('aria-expanded') == 'true' ) {
      hamDrawer.setAttribute('aria-hidden', false);
    } else {
      hamDrawer.setAttribute('aria-hidden', true);
    }

  }, false);
}

/**
 * ドロワーメニューの開閉
 */
export function drawer_menu() {
  document.addEventListener("DOMContentLoaded", function () {
    // 子メニューを持つ親要素をすべて取得
    const parentItems = document.querySelectorAll(".p-drawer .menu-item-has-children");

    parentItems.forEach((parent) => {
        // 親要素にクリックイベントを追加
        const toggleButton = document.createElement("button");
        toggleButton.classList.add("toggle-button");
        toggleButton.setAttribute("aria-expanded", "false");
        toggleButton.innerHTML = "▼";
        parent.appendChild(toggleButton);

        // サブメニューの参照を取得
        const subMenu = parent.querySelector(".sub-menu");

        toggleButton.addEventListener("click", function () {
            const isOpen = toggleButton.getAttribute("aria-expanded") === "true";

            // 開閉状態を切り替え
            toggleButton.setAttribute("aria-expanded", !isOpen);
            if (subMenu) {
                subMenu.style.display = isOpen ? "none" : "block";
            }
        });

        // 初期状態を閉じる
        if (subMenu) {
            subMenu.style.display = "none";
        }
    });
  });
}

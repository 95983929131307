/**
 * Splide
 */

import Splide from '@splidejs/splide';

// MV
export function kvSplide() {
  document.addEventListener( 'DOMContentLoaded', function() {

    var splideElement = document.querySelector('.splide');

    if (splideElement) {
      var splide = new Splide( '.splide', {
        autoplay: true,
        type: "loop",
        arrows: false,
        pauseOnHover: false,
        pauseOnFocus: true,
        interval: 6000,
        speed: 600,
        gap: 0,
        perPage: 1,
        padding: 0,
        drag: true,
        mediaQuery: 'min',
        breakpoints: {
          600: {
            drag: true,
          }
        }
      } );
      splide.mount();
    }
  });

}

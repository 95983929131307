/**
 * JavaScript for Theme setting.
 */

import { Processor } from "postcss";

// window size
export function window_size() {
  let windowWidth;
  let scrollbarWidth;
  let headerheight;
  let gNaviHeight;

  // ページ読み込み時
  window.addEventListener('DOMContentLoaded', function(){
    // ブラウザ内の表示域(スクロールバーを除く)
    windowWidth = document.documentElement.clientWidth;
    // scrollbarWidth = 15;
    scrollbarWidth = window.innerWidth - windowWidth;
    headerheight = document.getElementById('site-header').clientHeight;
    gNaviHeight = document.getElementById('header-global-nav').clientHeight;

    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--header-height-px', (headerheight) + 'px');
    document.documentElement.style.setProperty('--scrollbar-width-px', (scrollbarWidth) + 'px');
    document.documentElement.style.setProperty('--global-navi-height-px', (gNaviHeight) + 'px');
  });

  // ウィンドウのサイズ変更イベントの設定
  window.addEventListener('resize', function(){
    windowWidth = document.documentElement.clientWidth;
    scrollbarWidth = window.innerWidth - windowWidth;
    headerheight = document.getElementById('site-header').clientHeight;
    gNaviHeight = document.getElementById('header-global-nav').clientHeight;

    document.documentElement.style.setProperty('--window-width', windowWidth);
    document.documentElement.style.setProperty('--window-width-px', (windowWidth) + 'px');
    document.documentElement.style.setProperty('--header-height-px', (headerheight) + 'px');
    document.documentElement.style.setProperty('--scrollbar-width-px', (scrollbarWidth) + 'px');
    document.documentElement.style.setProperty('--global-navi-height-px', (gNaviHeight) + 'px');
  });
}

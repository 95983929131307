/**
 * Global Menu
 */

// メガメニュー
export function global_menu() {
  document.addEventListener("DOMContentLoaded", () => {
    const gNavi = document.querySelectorAll('[data-has="has-megamenu"] > span');

    // メガメニューを開閉する処理
    gNavi.forEach((trigger) => {
      trigger.addEventListener("click", () => {
        const parent = trigger.closest('[data-has="has-megamenu"]');
        const megaMenu = parent.querySelector('.p-megamenu');
        const isOpen = megaMenu.classList.contains('is-active');

        // 全てのメニューを閉じる
        document.querySelectorAll('.p-megamenu').forEach(menu => menu.classList.remove('is-active'));
        document.querySelectorAll('[data-has="has-megamenu"] > span').forEach(span => span.classList.remove('is-active'));

        // 現在のメニューを開閉
        if (!isOpen) {
          megaMenu.classList.add('is-active');
          trigger.classList.add('is-active');
        }
      });
    });

    // .closeボタンでメガメニューを閉じる処理
    document.querySelectorAll('.p-megamenu .close').forEach((closeButton) => {
      closeButton.addEventListener("click", () => {
        const megaMenu = closeButton.closest('.p-megamenu');
        megaMenu.classList.remove('is-active');

        // 関連するspanのactiveも解除
        const parent = megaMenu.closest('[data-has="has-megamenu"]');
        const trigger = parent.querySelector('span');
        trigger.classList.remove('is-active');
      });
  });

    // ページ外をクリックしたらメニューを閉じる
    document.addEventListener("click", (e) => {
      if (!e.target.closest('[data-has="has-megamenu"]')) {
          document.querySelectorAll('.p-megamenu').forEach(menu => menu.classList.remove('is-active'));
          document.querySelectorAll('[data-has="has-megamenu"] > span').forEach(span => span.classList.remove('is-active'));
      }
    });
  });

}

// カレント処理
export function global_menu_current() {
  document.addEventListener('DOMContentLoaded', function () {
    // 現在のページのパスを取得し、配列に分割
    var pageURL = location.pathname;
    var pageURLArr = pageURL.split('/'); // パスを分割して配列化
    var pageURLArrCategory = pageURLArr[1]; // パスから第1階層を取得

    // カテゴリとクラス名の対応表
    var categoryToClassMap = {
      'gas': 'nav-gas',
      'electricity': 'nav-electricity',
      'reform': 'nav-reform',
      'realestate': 'nav-realestate',
      'about': 'nav-overview',
      'communication': 'nav-communication'
    };

    // 対応するクラス名を取得
    var targetClass = categoryToClassMap[pageURLArrCategory];

    if (targetClass) {
      // 対応するクラスを持つspanにis-currentを追加
      var targetSpan = document.querySelector('.p-global-nav > ul > li.' + targetClass + ' > span');
      if (targetSpan) {
        targetSpan.classList.add('is-current');
      }
    }
  });
}
